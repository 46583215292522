import { graphql, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import Button from "../components/button"
import Contentbox from "../components/contentbox"
import H1 from "../components/h1"
import Input from "../components/input"
import Layout from "../components/layout"
import Narrow from "../components/narrow"
import Textarea from "../components/textarea"
import { colors } from "../utils/siteVars"

const StyledKontaktPage = styled.div`
  p.kontakt-text {
    max-width: 500px;
    margin: 0rem auto 2rem auto;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 1rem;
    align-items: flex-start;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      button {
        width: 100%;
      }
    }
    .single-card {
      border: 1px solid ${colors.mediumGrey};
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      display: grid;
      grid-template-columns: 3fr 1fr;
      overflow: hidden;
      .card-left {
        padding: 1rem;
        p {
          margin: 0;
          /* &.name {
            font-weight: 500;
            font-size: 1.1rem;
          }
          &.role {
            font-weight: 300;
            font-size: 0.95rem;
          } */
        }
      }
      .card-right {
        border-left: 1px solid ${colors.mediumGrey};
        background: ${colors.green};
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 30px;
          filter: invert(1);
        }
      }
    }
  }
`

const KontaktPage = () => {
  const query = useStaticQuery(graphql`
    query contactQuery {
      header: file(relativePath: { eq: "contact.jpg" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const handleSubmit = e => {
    // e.preventDefault()
  }

  const persons = [
    {
      name: "Gunnar Wilson",
      phone: "070 78 02 570",
      email: "gunnar@cykeltvatten.se",
      role: "Försäljning",
    },
    {
      name: "Johan Bjerker",
      phone: "070 48 08 041",
      email: "info@cykeltvatten.se",
      role: "Support, el, data",
    },
    {
      name: "Sten Nord",
      phone: "070 79 44 544",
      email: "sten@cykeltvatten.se",
      role: "Teknik",
    },
  ]

  return (
    <Layout
      headerImage={query.header.childImageSharp.fluid}
      headerText="Kontakta oss"
      helmet="Kontakt"
    >
      <StyledKontaktPage>
        <Narrow>
          <H1>Kontakta oss</H1>
          <p className="centered kontakt-text">
            Kontakta oss gärna för mer information, offerter, teknisk rådgivning
            eller annat som rör Cykeltvätten. <br />
            Vi finns här för att hjälpa dig!
          </p>
          <div className="grid-container">
            <Contentbox>
              <form
                onSubmit={handleSubmit}
                method="post"
                action="/tack"
                netlify-honeypot="bot-field"
                data-netlify="true"
                name="Kontakt"
              >
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="Kontakt" />
                <Input
                  name="Namn"
                  type="text"
                  value={name}
                  onChange={e => {
                    setName(e.target.value)
                  }}
                  label="Ditt namn"
                  placeholder="Ditt namn"
                />
                <Input
                  name="E-post"
                  type="email"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                  label="E-post"
                  placeholder="E-post"
                />
                <Textarea
                  name="Meddleande"
                  label="Ditt meddelande"
                  value={message}
                  onChange={e => {
                    setMessage(e.target.value)
                  }}
                  placeholder="Ditt meddelande"
                />
                <Button type="submit">Skicka</Button>
              </form>
            </Contentbox>
            <div className="contact-cards">
              {persons.map(person => (
                <div className="single-card" key={person.name}>
                  <div className="card-left">
                    <p className="name">{person.name}</p>
                    <p className="role">{person.role}</p>
                    <p>{person.phone}</p>
                    <a href={`mailto:${person.email}`}>
                      <p>{person.email}</p>
                    </a>
                  </div>
                  <a href={`mailto:${person.email}`} className="card-right">
                    <div>
                      <img src="/images/mail.png" alt="skicka mail" />
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </Narrow>
      </StyledKontaktPage>
    </Layout>
  )
}

export default KontaktPage
